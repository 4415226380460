import $ from 'jquery'
import { Section } from '../../common/section'
import { SectionManager } from '../../common/section_manager'
import './main.scss'
import { DateTime as dateTime } from 'luxon'

export class CountdownSection extends Section {
  constructor(...args) {
    super(...args)
    this.updateTimer = this.updateTimer.bind(this)
    this.showEndedTextIfAvailable = this.showEndedTextIfAvailable.bind(this)
  }

  static initClass() {
    this.type = 'countdown'
  }

  onLoad() {
    const settings = this.elem.find('[data-countdown-end-time]').data()

    if (!settings) return

    if (!settings.countdownMode || settings.countdownMode === 'end_time') {
      this.endTime =
        this.elem
          .find('[data-countdown-end-time]')
          .data('countdown-end-time') || null
    } else if (
      settings.countdownMode === 'time_offset_relative_to_activation'
    ) {
      const offsetSettings = settings.countdownTimeOffsetRelativeToActivation
      const { count, unit, activationTimeOfDay, activationTimeOfDayUTCOffset } =
        offsetSettings
      let { activationTime } = offsetSettings

      const hours =
        unit === 'days' ? count * 24 : unit === 'minutes' ? count / 60 : count

      if (activationTime && count) {
        const ms = hours * 60 * 60 * 1000
        activationTime += ms

        if (activationTimeOfDay) {
          // Go to the time of day in the future.
          let timeOfDayParts = /([0-9]{1,2}):([0-9]{2}) ([AP]M)/.exec(
            activationTimeOfDay
          )

          if (timeOfDayParts) {
            let date = new Date(activationTime)
            let hours = parseInt(timeOfDayParts[1])
            if (timeOfDayParts[3] == 'PM') {
              hours += 12
            }
            let minutes = parseInt(timeOfDayParts[2])

            // Convert the time of day to the JS time zone
            let timeInMinutes = hours * 60 + minutes
            timeInMinutes =
              timeInMinutes -
              date.getTimezoneOffset() -
              parseInt(activationTimeOfDayUTCOffset)

            // Handle going to another day
            if (timeInMinutes < 0) {
              timeInMinutes += 60 * 24
            } else if (timeInMinutes > 60 * 24) {
              timeInMinutes -= 60 * 24
            }

            // Convert back to hours and minutes, now in the JS time zone.
            hours = Math.floor(timeInMinutes / 60)
            minutes = timeInMinutes % 60

            // Might be tomorrow.
            if (
              date.getHours() > hours ||
              (date.getHours() == hours && date.getMinutes() > minutes)
            ) {
              date.setDate(date.getDate() + 1)
            }

            date.setHours(hours)
            date.setMinutes(minutes)
            activationTime = date.valueOf()
          } else {
            console.log('Unrecognized time of day: ' + activationTimeOfDay)
          }
        }

        this.endTime = activationTime
      } else if (hours > 0) {
        this.endTime = this.addHoursToDate(hours)
      }
    }

    if (this.endTime) {
      document
        .querySelector(`#${this.elem[0].id} #end-date-message-container`)
        ?.classList.remove('hidden')

      this.secondsElem = this.elem.find('.countdown__value--seconds')
      this.minutesElem = this.elem.find('.countdown__value--minutes')
      this.hoursElem = this.elem.find('.countdown__value--hours')
      this.daysElem = this.elem.find('.countdown__value--days')

      this.timer = setInterval(this.updateTimer, 1000)
      return this.updateTimer()
    } else {
      document
        .querySelector(`#${this.elem[0].id} #end-date-message-container`)
        ?.classList.add('hidden')
      return this.showEndedTextIfAvailable()
    }
  }

  onUnload() {
    if (this.timer) {
      return clearInterval(this.timer)
    }
  }

  updateTimer() {
    let secondsDiff = Math.floor((this.endTime - new Date().valueOf()) / 1000)
    if (secondsDiff < 0) {
      secondsDiff = 0
    }
    const lastUpdate = secondsDiff === 0

    const daysDiff = Math.floor(secondsDiff / 86400)
    secondsDiff -= daysDiff * 86400
    const hoursDiff = Math.floor(secondsDiff / 3600)
    secondsDiff -= hoursDiff * 3600
    const minutesDiff = Math.floor(secondsDiff / 60)
    secondsDiff -= minutesDiff * 60

    this.secondsElem.text((secondsDiff > 9 ? '' : '0') + secondsDiff.toString())
    this.minutesElem.text((minutesDiff > 9 ? '' : '0') + minutesDiff.toString())
    this.hoursElem.text((hoursDiff > 9 ? '' : '0') + hoursDiff.toString())
    this.daysElem.text((daysDiff > 9 ? '' : '0') + daysDiff.toString())

    if (lastUpdate) {
      clearInterval(this.timer)
    }

    const hasEndDateMessage = document.querySelector(
      `#${this.elem[0].id} #end-date-message`
    )

    if (hasEndDateMessage && this.endTime) {
      hasEndDateMessage.innerHTML = dateTime
        .fromISO(new Date(this.endTime).toISOString())
        .toFormat('EEEE @ hh:mma')
    }

    if (lastUpdate) {
      return this.showEndedTextIfAvailable()
    }
  }

  showEndedTextIfAvailable() {
    document
      .querySelector(`#${this.elem[0].id} #end-date-message-container`)
      ?.classList.add('hidden')

    if (this.endedText().length === 0) {
      return
    }
    if (this.endedText().hasClass('live-edit-mode')) {
      return
    }

    this.elem
      .find(
        '.countdown, .section__header-text, .hero-countdown__active-content'
      )
      .hide()
    return this.endedText().show()
  }

  endedText() {
    return this.elem.find('.countdown__ended-text')
  }

  addHoursToDate(hours) {
    return new Date(new Date().getTime() + hours * 60 * 60 * 1000)
  }
}
CountdownSection.initClass()

$(function () {
  new SectionManager([CountdownSection])
})
